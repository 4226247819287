import React, { useEffect, useRef } from 'react';

interface LoginProps {
  onLogin: () => void;
}

const LoginWidget: React.FC<LoginProps> = ({ onLogin }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  //const origin = environment === 'production' ? 'https://brk9801-poc02-website.fd-broker9801.com' : 'https://brk9801-poc03-website.fd-broker9801.com';
  const origin = 'https://brk0201-mobilewaigr-website.nyrabets.com/widgets/login';

  useEffect(() => {
    const loginWidgetOrigin = origin;

    const receiveMessage = (event: MessageEvent) => {
      if (event.origin !== loginWidgetOrigin) {
        return;
      }

      const data = event.data;

      if (data.messageName === 'loginResponse') {
        //console.info(`Partner Site: loginResponse message received -> ${JSON.stringify(data)}`);
        const sessionToken = data.sessionToken;
        localStorage.setItem('sessionToken', sessionToken);
        onLogin();
      }
    };

    window.addEventListener('message', receiveMessage);

    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, [onLogin]);

  return (
    <div>
      <iframe
        id="loginWidget"
        title="NYRA Login"
        ref={iframeRef}
        src={`${origin}/widgets/login/`}
        onLoad={() => {
          const loginWidgetOrigin = origin;
          iframeRef.current?.contentWindow?.postMessage({ messageName: 'init' }, loginWidgetOrigin);
        }}
        style={{
          minHeight: '100vh',
          width: '100%',
          border: 'none',
        }}
      />
    </div>
  );
};

export default LoginWidget;
